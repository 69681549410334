var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { staticClass: "main-cbo" },
    [
      _c("Header", {
        attrs: {
          title: _vm.txt.headerTitle,
          subtitle: _vm.txt.headerSubtitle,
          itemsBreadCrumb: _vm.itemsBreadCrumb,
          hasUpdateInfo: false,
          hasAddButton: false,
        },
      }),
      _c("CboTabs"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }