<template>
  <v-main class="main-cbo">
    <Header
      :title="txt.headerTitle"
      :subtitle="txt.headerSubtitle"
      :itemsBreadCrumb="itemsBreadCrumb"
      :hasUpdateInfo="false"
      :hasAddButton="false"
    />
    <CboTabs/>
  </v-main>
</template>

<script>
export default {
  name: 'CBO',
  components: {
    Header: () => import('../../commons/Header.vue'),
    CboSearch: () => import('./components/CboSearch.vue'),
    CboTabs: () => import('./components/CboTabs.vue')
  },

  data() {
    return {
      txt: {
        headerTitle: 'Classificação Brasileira de Ocupações (CBO)',
        headerSubtitle: 'Monitore e encontre recomendações de ocupações dos trabalhadores',
      },
      itemsBreadCrumb: [
        {
          title: 'Home',
          disabled: false,
          href: 'home',
        },
        {
          title: 'e-Social',
          disabled: true,
        },
        {
          title: 'Trabalhadores',
          disabled: true,
        },
        {
          title: 'CBO',
          disabled: true,
          active: true,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 1920px) {
  .main-cbo {
    ::v-deep {
      .v-main__wrap {
        width: 100%;
        min-width: 1792px;
        max-width: 1792px !important;
      }
    }
  }
}
</style>
